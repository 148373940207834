
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { defaultLocale } from "@/i18n/config";
import { LayoutLanguages, I18nLanguage } from "@/i18n/config/locales";

export default defineComponent({
    name: "TranslationView",
    data: () => ({
        LayoutLanguages, defaultLocale
    }),
    computed: {
        ...mapGetters({
            getLayout: "getLayout"
        }),
    },
    methods: {
        mapLanguage(languages: I18nLanguage[]) {
            return languages.map((language: I18nLanguage) => ({
                optionText: language.title,
                id: language.param
            }))
        }
    }
});
