export const formatNumberMixin = {
  methods: {
    formatNumber(value: string): string {
      if (value == null || value == "") {
        return "-";
      }

      const locale = "nl-NL";
      if (locale === "nl-NL") {
        return value ? value.split(".").join(",") : "";
      } else {
        return value ? value : "";
      }
    },
  },
};
