<script>
// import CrvIcon from '~components/icon/crv-icon'
// import { logoWithTagline, phoneAltSolid, sloganCrv } from '../../assets/icons'
import crvLogo from '../../assets/logo/crvLogo.vue';
import phoneAltSolid from '../../assets/icons/phoneAltSolid.vue';
import sloganCrv from '../../assets/icons/sloganCrv.vue';
import moment from 'moment'
import DateHelper from './dateHelper.js'

export default {
    name: 'crv-pdf-page',
    components: { crvLogo, phoneAltSolid, sloganCrv },

    props: {
        isFirstPage: {
            type: Boolean,
            default: false,
            note: 'Shows the default main header on top of the page.'
        },

        title: {
            type: String,
            default: '',
            note: 'Required title of the document',
        },

        subtitle: {
            type: String,
            note: 'Optional subtitle of the document',
        },

        identifier: {
            type: String,
            note: 'Display information about the customer'
        },

        footerText: {
            type: String,
            default: 'Coöperatie CRV Postbus 454 6800 AL Arnhem',
            note: 'Text displayed inside the footer'
        },

        phoneNumber: {
            type: String,
            default: '088 0024440',
            note: 'Phonenumber of customer-support set in the footer'
        },

        dateLabel: {
            type: String,
            note: 'Overwrite of the displayed date inside the footer'
        }


    },

    methods: {
        printDate() {
            let dateHelper = DateHelper(moment)
            const currentDate = new Date()
            return dateHelper.getMonthDayYearShort(currentDate)
        }
    },
}
</script>

<template>
    <div class="pdf-page">
        <div v-if="isFirstPage" class="pdf-header">
            <crvLogo class="logo"/>
            <div class="pdf-header__text">
                <h2 class="pdf-title">{{title}}</h2>
                <h3 class="pdf-subtitle">{{subtitle}}</h3>
                <p class="pdf-customer">{{ identifier }}</p>
            </div>
        </div>
        <slot></slot>
        <div class="pdf-footer">
            <p>{{ footerText }}</p>
            <span class="phone-number" >
                <phone-alt-solid class="phone-icon" />
                {{phoneNumber}}
            </span>
            <span>
                <slogan-crv />
            </span>
            <p>{{ dateLabel || printDate() }}</p>
            <p class="page-numbers-placeholder">&numsp;</p>
        </div>
    </div>
</template>


<style lang="scss" scoped>
$vertical-margins: $vertical-spacing-half;
$horizontal-margins: $horizontal-spacing-double;

.pdf-page {
    width: 100%;
    position: relative;
    padding-left: 60px;
    padding-right: 8px;
    // margin for perforated printed document
    // margin-left: 60px;

    .pdf-header {
        display: flex;
        height: 80px;
        width: 100%;

        min-height: unset;
        margin-bottom: $vertical-margins;

        .logo {
            width: 160px;
            height: 64px;
        }

        &__text > * {
            margin-bottom: 8px;
        }

        > * {
            margin-right: $vertical-spacing-double;
        }

        .pdf-title {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 8px;
            line-height: 21px;
        }

        .pdf-subtitle {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;
            line-height: 21px;
        }

        .pdf-customer {
            // Explicitly set for offline pdf-printing (otherwise styling is missing)
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 8px;
            line-height: 21px;
        }
    }

    .pdf-footer {
        position: absolute;
        bottom: 5px;
        margin-bottom: 1px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        svg.slogan-crv {
            width: 145px; 
            height: 8px;
        }

        .phone-icon {
            width: 15px; 
            height: 15px; 
            margin: 2px 5px 0 0;
        }

        .phone-number {
            display: flex;
        }

        .page-numbers-placeholder {
            width: 75px;
            margin-left: 56px;

        }

        > * {
            margin: $vertical-margins $horizontal-spacing-single;
            font-size: 10px;
        }
    }
}
</style>
