import moment from "moment";

export const formatDateMixin = {
    methods: {
        formatDate(dateString: string, fullYear?: boolean): string {
            if (!dateString) {
              return '-';
            }
          
            if (fullYear) {
              return moment(dateString).format('DD-MM-YYYY');
            } else {
              return moment(dateString).format('DD-MM-YY');
            }
          }
    }
  };