/*
    Returns the value of passed url parameter.
    Returns and empty string when not found.
    Removes the parameters from url after they have been used.
 */
export function getUrlParameterByName(name: string) : string{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchTerm = urlParams.get(name);

    if (searchTerm === null || searchTerm === undefined || searchTerm === ""){
        return "";
    }

    return searchTerm;
}

/*
    Removes parameters from url, without navigating.
 */
export function removeUrlParameters(){
    window.history.replaceState(null, '', window.location.pathname);
}