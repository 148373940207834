
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProductionTable',
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        },
        footerRows: {
            type: Array
        }
    },
});
