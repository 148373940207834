
import { defineComponent } from 'vue';
import { formatDateMixin } from '../../mixins/format-date';
import { formatValueOrDash } from '../../scripts/format-value-or-dash';
import { mapGetters } from 'vuex';
import {formatInheritedFactors} from "../../scripts/format-inherited-factors";
import {defaultLocale} from "../../i18n/config";

export default defineComponent({
    name: 'ListBlockView',
    mixins:[formatDateMixin],
    computed: {
      defaultLocale() {
        return defaultLocale
      },
        ...mapGetters({
            bullDetail: 'getBullDetail',
        }),
    },
    methods: {
      formatInheritedFactors,
      formatValueOrDash,
        formatEmptyToDash(value: number | string, round?: boolean) {
            if (typeof value === 'string') {
                const nullValue = 'null';
                const newValue = (value as string).replace(nullValue, this.$t('details.unknown'))
                return formatValueOrDash(newValue);
            } else {
                return formatValueOrDash(value, round);
            }
        }
    },
});
