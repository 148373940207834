
import {defineComponent} from "vue"
import toolsImage from '../assets/svg/toolsImage.vue'
import SireFooter from '../components/SireFooter.vue'

export default defineComponent({
  name: "MaintenanceView",

  components: {
    toolsImage,
    SireFooter
    }
})
