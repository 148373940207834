import { BullInfo } from '@/models/bullinfo';
import { FilterExpression } from '@/models/filterexpression';
import { createStore } from 'vuex';

export interface BullState {
  searchText: string;
  bulls: Array<BullInfo>;
  bullDetail: BullInfo;
  layout: string;
  filteredValues: FilterExpression[]
  pedigreeState: number;
  pedigreeBull: BullInfo;
  previousLayout: string;
  baseColor: string
  calcType: string
}

export default createStore({
  state: {
    searchText: '',
    bulls: [],
    bullDetail: null,
    layout: 'search-view',
    filteredValues: [],
    pedigreeState: 1,
    pedigreeBull: null,
    previousLayout: null,
    baseColor: 'Zwartbont',
    calcType: 'GENOMIC_BREEDING_VALUE'
  } as unknown as BullState,
  getters: {
    getSearchText(state: BullState) {
      return state.searchText;
    },
    getBulls: (state: BullState) => state.bulls,
    getBullDetail: (state: BullState) => state.bullDetail,
    getLayout(state: BullState) {
      return state.layout;
    },
    getFilteredValues(state: BullState) {
      return state.filteredValues;
    },
    getPedigreeState(state: BullState) {
      return state.pedigreeState;
    },
    getPedigreeBull(state: BullState) {
      return state.pedigreeBull;
    },
    getPreviousLayout(state: BullState) {
      return state.previousLayout;
    },
    getBaseColor(state: BullState) {
      return state.baseColor;
    },
    getCalcType(state: BullState) {
      return state.calcType;
    },
  },
  mutations: {
    setSearchText(state: BullState, payload) {
      state.searchText = payload;
    },
    setBulls(state: BullState, payload) {
      state.bulls = payload;
    },
    setBullDetail(state: BullState, payload) {
      state.bullDetail = payload;
    },
    setBreedingValueBase(state: BullState, payload) {
      state.bullDetail.breedingValueBase = payload;
    },
    setLayout(state: BullState, payload) {
      state.layout = payload;
    },
    setFilteredValues(state: BullState, payload) {
      state.filteredValues = payload;
    },
    setPedigreeState(state: BullState, payload) {
      state.pedigreeState = payload;
    },
    setPedigreeBull(state: BullState, payload) {
      state.pedigreeBull = payload;
    },
    setPreviousLayout(state: BullState, payload) {
      state.previousLayout = payload;
    },
    setBaseColor(state: BullState, payload) {
      state.baseColor = payload;
    },
    setCalcType(state: BullState, payload) {
      state.calcType = payload;
    }
  }
});
