
import { defineComponent } from 'vue';
import { formatNumber } from '../../scripts/format-number'
import { translateDaysMixin } from '../../mixins/translate-days';

export default defineComponent({
    name: 'CustomTableView',
    mixins: [translateDaysMixin],
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    },
    methods: {
        formatNumber(value: string) {
            return formatNumber(value)
        }
    }
});
