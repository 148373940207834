import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57a709db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail" }
const _hoisted_2 = { class: "detail__container" }
const _hoisted_3 = { class: "detail__header" }
const _hoisted_4 = { class: "detail__header-sub" }
const _hoisted_5 = { class: "detail__select" }
const _hoisted_6 = { class: "detail__tab-container" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "detail__collapse-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevronLeftSolid = _resolveComponent("chevronLeftSolid")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_GeneralView = _resolveComponent("GeneralView")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_PedigreeView = _resolveComponent("PedigreeView")!
  const _component_BreedingValuesView = _resolveComponent("BreedingValuesView")!
  const _component_GenomicsView = _resolveComponent("GenomicsView")!
  const _component_DocumentationFormulasView = _resolveComponent("DocumentationFormulasView")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_SireFooter = _resolveComponent("SireFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_card_title, { class: "crv-header crv-header level-one" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBackToSearch && _ctx.goBackToSearch(...args))),
                class: "detail__header__svg"
              }, [
                _createVNode(_component_chevronLeftSolid)
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.formatEmptyToDash(_ctx.bullDetail.shortName ? _ctx.bullDetail.shortName : _ctx.bullDetail.fullName)), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bullDetail.fullName ? _ctx.bullDetail.fullName : '-') + " | " + _toDisplayString(_ctx.bullDetail.artificialInseminationNumber) + " | " + _toDisplayString(_ctx.bullDetail.interbullNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_select, {
            items: _ctx.getHairColorOptions,
            density: "compact",
            label: _ctx.$t('details.basic-calc'),
            "bg-color": "white",
            modelValue: _ctx.getHairColorValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getHairColorValue) = $event)),
            "item-title": "optionText",
            "item-value": "id",
            "return-object": "",
            variant: "underlined",
            color: "primary"
          }, null, 8, ["items", "label", "modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_v_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tab) = $event)),
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setGeneralView())),
              color: _ctx.baseColor,
              value: "one"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              color: _ctx.baseColor,
              value: "two"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setBreedingValue())),
              color: _ctx.baseColor,
              value: "three"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_tab, {
              color: _ctx.baseColor,
              value: "five"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
              ]),
              _: 1
            }, 8, ["color"])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_window, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tab) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_window_item, { value: "one" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GeneralView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "two" }, {
              default: _withCtx(() => [
                _createVNode(_component_PedigreeView, { onClicked: _ctx.searchBullsByITB }, null, 8, ["onClicked"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "three" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_BreedingValuesView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "four" }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_GenomicsView, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_v_progress_circular, {
                        indeterminate: "",
                        color: "#154775",
                        "model-value": "20"
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { value: "five" }, {
              default: _withCtx(() => [
                _createVNode(_component_DocumentationFormulasView)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_v_expansion_panels, {
          variant: "accordion",
          multiple: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setGeneralView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-overview')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GeneralView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-origin')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_PedigreeView, { onClicked: _ctx.searchBullsByITB }, null, 8, ["onClicked"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setBreedingValue()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-breeding')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_BreedingValuesView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setGenonimcView()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('details.header-genomics')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_GenomicsView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_expansion_panel, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('header.tab-docs')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DocumentationFormulasView)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_SireFooter)
  ], 64))
}