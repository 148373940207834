/*
  Returns the passed value, or when the value is erroneous a dash '-' will be returned.

  Is used for displaying values visually appealing.
 */
export function formatValueOrDash(
  value: number | string | undefined | null,
  round?: boolean
): number | string | undefined {
  if (typeof(value) == "string") {
    if (Number(value) === 0 && Number(value) === 0) {
      return "-";
    }
  }

  if (value === null || value === undefined || value === "" || value === 0 || value === "0" || isBlank(value)) {
    return "-";
  }
  const locale = "nl-NL";

  if (round) {
    const valueNumber = Math.round(value as number);
    return valueNumber || valueNumber === 0 ? valueNumber.toLocaleString(locale) : "-";
  } else {
    return value;
  }
}

/*
  Check if string is empty or whitespaces only.
 */
function isBlank(value) {
  return (!value || /^\s*$/.test(value));
}
