
import {defineComponent} from "vue";
import {CrvSearch} from '@crv/crv-common';
import noSearchResults from '../assets/svg/noSearchResults.vue';
import {mapGetters, mapMutations} from "vuex";
import {BullService} from "../services/BullService";
import {formatDateMixin} from "../mixins/format-date";
import {formatValueOrDash} from "../scripts/format-value-or-dash";
import {BullInfo} from "@/models/bullinfo";
import {Device} from "@/models/enums";
import {FilterExpression} from "@/models/filterexpression";
import {mdiFilter} from '@mdi/js';
import {formatNumberMixin} from '../mixins/format-number'
import {getUrlParameterByName, removeUrlParameters} from "../scripts/get-url-parameters";
import {Locales} from "@/i18n/config/locales";
import DisclaimerFooter from "@/components/DisclaimerFooter.vue";

export const bullService = new BullService();
const bullDetail: any = {}
const filterValues: FilterExpression[] = []

export default defineComponent({
  name: "SearchView",
  mixins: [formatDateMixin, formatNumberMixin],
  components: {noSearchResults, CrvSearch, DisclaimerFooter},
  data() {
    return {
      dialog: false,
      path: mdiFilter,
      filteredValues: filterValues,
      windowWidth: 0,
      search: "",
      isLoading: false,
      bullJson: [] as BullInfo[],
      bullDetail: bullDetail,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.triggerSearchIfNeeded();
  },
  computed: {
    ...mapGetters({
      getBulls: "getBulls",
      getSearchText: "getSearchText",
      getFilteredValues: "getFilteredValues"
    }),
    getSortableHeaderRows(): { name: string, sortable: boolean, sortDirection?: string, initialSortColumn?: boolean }[] {
      return [
        {
          "name": 'lifeNumberHidden',
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.ai'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.fullName'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.shortName'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.interbullNumber'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.dateOfBirth'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.nvi'),
          "sortable": true,
          "sortDirection": "desc",
          "initialSortColumn": true
        },
        {
          "name": this.$t('home.table-headers.inet'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.milkKilograms'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.percentageFat'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.percentageProtein'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.foodCostSaving'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.lifeSpan'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.udderHealth'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.fertility'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.clawHealth'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.birthIndex'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.birthEase'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.totalExterior'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.udder'),
          "sortable": true
        },
        {
          "name": this.$t('home.table-headers.legwork'),
          "sortable": true
        }
      ]
    },
    mapBulls(): string[] {
      return this.getBulls.map((bull: BullInfo) => ({
        "id": bull.primaryKey,
        "data": [
          {
            "value": bull.lifeNumber
          },
          {
            "value": formatValueOrDash(bull.artificialInseminationNumber),
            "isNumber": true
          },
          {
            "value": formatValueOrDash(bull.fullName)
          },
          {
            "value": formatValueOrDash(bull.shortName)
          },
          {
            "value": formatValueOrDash(bull.interbullNumber)
          },
          {
            "value": bull.dateOfBirth?.toString(),
            "formattedValue": this.formatDate(bull.dateOfBirth),
            "isNumber": true
          },
          {
            "value": (bull.dutchFlemishIndex * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.dutchFlemishIndex?.toString())
          },
          {
            "value": (bull.inet * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.inet?.toString())
          },
          {
            "value": (bull.milkKilograms * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.milkKilograms?.toString())
          },
          {
            "value": bull.percentageFat === null ? '-' : (bull.percentageFat * 1000).toString(),
            "formattedValue": bull.percentageFat === null ? '-' : this.formatNumber(Number(bull.percentageFat).toFixed(2)?.toString())
          },
          {            
            "value": bull.percentageProtein === null ? '-' : (bull.percentageProtein * 1000).toString(),
            "formattedValue": bull.percentageProtein === null ? '-' : this.formatNumber(Number(bull.percentageProtein).toFixed(2)?.toString())
          },
          {
            "value": (bull.foodCostSaving * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.foodCostSaving?.toString())
          },
          {
            "value": (bull.lifeSpan * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.lifeSpan?.toString())
          },
          {
            "value": (bull.udderHealth * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.udderHealth?.toString())
          },
          {
            "value": (bull.fertility * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.fertility?.toString())
          },
          {
            "value": (bull.clawHealth * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.clawHealth?.toString())
          },
          {
            "value": (bull.birthIndex * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.birthIndex?.toString())
          },
          {
            "value": (bull.birthEase * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.birthEase?.toString())
          },
          {
            "value": (bull.totalExterior * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.totalExterior?.toString())
          },
          {
            "value": (bull.udder * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.udder?.toString())
          },
          {
            "value": (bull.legwork * 1000).toString(),
            "isNumber": true,
            "formattedValue": this.formatNumber(bull.legwork?.toString())
          },
        ],
      }))
    }
  },
  methods: {
    ...mapMutations({
      setBulls: "setBulls",
      setBullDetail: "setBullDetail",
      setLayout: "setLayout",
      setSearchText: "setSearchText",
      setFilteredValues: "setFilteredValues",
      setCalcType: "setCalcType",
      setBaseColor: "setBaseColor"
    }),
    handleDrawer(filteredArray: FilterExpression[]) {
      this.dialog = false
      this.setFilteredValues(filteredArray.filter(filter => filter))
      this.filteredValues = this.getFilteredValues
      this.searchBullsBy(this.getSearchText)
    },
    resetClick() {
      const reset = document.querySelectorAll('.display-icon')[0] as HTMLElement
      reset.dispatchEvent(new Event('click'));
      try {
        reset.click();
      } catch (error) {
        return;
      }
    },
    resetDrawer() {
      this.dialog = false
      this.setFilteredValues([]);
      this.filteredValues = [];
      this.setBulls([]);
      this.setSearchText(null);
      this.search = ''
      this.resetClick()
    },
    async searchBullsBy(value: string) {

      this.search = value;
      this.setSearchText(this.search);

      if (this.filteredValues.length < 1) {
        if (value.length == 0) {
          this.setState([]);
        }
        if (value.length < 3 || !value) {
          return;
        }
      }

      this.isLoading = true;
      try {
        const result = await bullService.getForSearchView(value, this.getFilteredValues);

        if (result) {
          this.isLoading = false
        }
        this.bullJson = result.length === 0 ? [] : result;


      } catch (error) {
        this.isLoading = false
      }

      this.setState(this.bullJson);
    },
    async handleClickBodyRow(id: string) {
      const result: BullInfo = await bullService.getForDetailView(id);
      this.bullDetail = result

      this.setBullDetail(this.bullDetail);
      this.setBaseColor(this.bullDetail.referenceBase)
      if (this.bullDetail) {
        this.setLayout('bull-detail-view')
      }
    },
    /**
     * Fills the state with last queried bulls (bullJson)
     * Call this function after every query so that the state will always be up-to-date.
     */
    setState(state: BullInfo[]) {
      this.setBulls(state);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    removeFilters(): void {
      this.setFilteredValues([]);
      this.setBulls([])
      this.filteredValues = [];
      this.resetClick()
    },
    translateFilterNames(fieldName: string): string {
      return this.$t(`filter.headers.${fieldName}`)
    },
    triggerSearchIfNeeded() {
      const searchTermUrlParameterValue = getUrlParameterByName("searchTerm");
      if (searchTermUrlParameterValue != "") {
        this.triggerSearchByUrlParameters(searchTermUrlParameterValue);
      } else {
        this.triggerSearchBySearchFieldInput();
      }

      const localeUrlParameterValue = getUrlParameterByName("locale");
      if (localeUrlParameterValue != ""){
       this.triggerLocaleChangeByUrlParameter(localeUrlParameterValue);
      }

      removeUrlParameters();
    },
    triggerSearchByUrlParameters(searchTermUrlParameterValue: string){
      const searchInputField = (document.querySelectorAll('*[id^="crv-input"]')[0] as HTMLInputElement);

      searchInputField.value = searchTermUrlParameterValue;
      searchInputField.dispatchEvent(new Event("input"));
    },
    triggerSearchBySearchFieldInput(){
      const searchInputField = (document.querySelectorAll('*[id^="crv-input"]')[0] as HTMLInputElement);
      searchInputField.value = this.getSearchText;
      searchInputField.dispatchEvent(new Event("input"));
    },
    triggerLocaleChangeByUrlParameter(localeUrlParameterValue: string){
      switch (localeUrlParameterValue) {
        case Locales.nlNl:
          this.$i18n.locale = localeUrlParameterValue;
          break;
        case Locales.enUs:
          this.$i18n.locale = localeUrlParameterValue;
          break;
        default:
          this.$i18n.locale = Locales.nlNl;
      }
    }
  },
  setup() {
    return {
      Device
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
});
