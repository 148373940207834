import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6628ce79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home__header" }
const _hoisted_3 = { class: "home__search" }
const _hoisted_4 = { class: "home__search__component" }
const _hoisted_5 = {
  key: 0,
  class: "home__no-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_crv_search = _resolveComponent("crv-search")!
  const _component_noSearchResults = _resolveComponent("noSearchResults")!
  const _component_DisclaimerFooter = _resolveComponent("DisclaimerFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_card_title, { class: "crv-header crv-header level-two" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("home.header")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_crv_search, {
            "has-clickable-rows": "",
            "is-loading": _ctx.isLoading,
            "placeholder-text": (_ctx.windowWidth > _ctx.Device.MOBILEMAX) ? _ctx.$t('home.placeholder-text') : _ctx.$t('home.placeholder-text-mobile'),
            "has-result-count": "",
            context: (_ctx.search?.length > 2) ? { item: _ctx.$t('home.helper-single'), items: _ctx.$t('home.helper-multiple') } : undefined,
            "summary-text": (_ctx.search?.length < 3 && _ctx.getBulls.length < 1) ? {
                        foundNoneText: _ctx.$t('home.helper-text'),
                        foundOneText: _ctx.$t('home.helper-text'),
                        foundManyText: _ctx.$t('home.helper-text'),
                    } : {
                        foundNoneText: _ctx.$t('home.foundNoneText'),
                        foundOneText: _ctx.$t('home.foundOneText'),
                        foundManyText: `${_ctx.getBulls.length} ${_ctx.$t('home.foundManyText')}`,
                    },
            onSearchChanged: _ctx.searchBullsBy,
            rows: _ctx.mapBulls,
            columns: _ctx.getSortableHeaderRows,
            onClickRow: _ctx.handleClickBodyRow
          }, null, 8, ["is-loading", "placeholder-text", "context", "summary-text", "onSearchChanged", "rows", "columns", "onClickRow"])
        ])
      ]),
      (_ctx.search?.length < 3 && _ctx.getBulls.length < 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_noSearchResults)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_DisclaimerFooter)
  ], 64))
}