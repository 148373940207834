import {
    Locales
} from './locales'

import enUs from '../en_us.json'
import nlNl from '../nl_nl.json'

export const messages = {
    [Locales.enUs]: enUs,
    [Locales.nlNl]: nlNl,
}

const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

export const defaultLocale = userLocale === 'en-US' ? Locales.enUs : Locales.nlNl;