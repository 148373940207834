import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f6b8498"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-cell-container" }
const _hoisted_2 = { class: "hidden" }
const _hoisted_3 = { class: "header-cell-middle" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "cell-container"
}
const _hoisted_7 = { class: "subTextLeft" }
const _hoisted_8 = { class: "hobv-bar" }
const _hoisted_9 = { class: "subTextRight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorizontalBreedingValueBar = _resolveComponent("HorizontalBreedingValueBar")!
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, { density: "compact" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "text-center",
              key: item.id
            }, [
              (item.slotName === 'custom-styled-column1')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["breeding__general__bar-header", _ctx.hideHeader ? 'hidden' : null])
                  }, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 0, 2)), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 2, 2)), 1),
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 4, 3)), 1),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 4, 3)), 1)
                    ]),
                    _createElementVNode("div", null, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 7, 3)), 1),
                    _createElementVNode("div", null, _toDisplayString(_ctx.setHeaderForHOVOBar(item.value, 10, 3)), 1)
                  ], 2))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.value), 1))
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                key: cell.id,
                class: _normalizeClass(cell.slotName === 'text-left' ? 'text-left' : 'text-center')
              }, [
                (cell.slotName === 'custom-styled-column-3')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["cell-container-measurement", _ctx.isEfficiency ? 'efficiency' : ''])
                    }, _toDisplayString(_ctx.isEfficiency ? _ctx.returnNumberorString(cell.value.value, cell.value.tagName) : _ctx.formatEmptyToDash(cell.value.value, true)) + " " + _toDisplayString(_ctx.translateDaysMixin(cell.value.unitOfMeasurement)), 3))
                  : _createCommentVNode("", true),
                (!(cell.id === 'value' || cell.id === 'measurement'))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.showIndentationForMilkingRobot(cell.value)), 1))
                  : _createCommentVNode("", true),
                (cell.slotName === 'custom-styled-column')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.addDutchTranslatedSubtextLeft(row.id)), 1),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_HorizontalBreedingValueBar, {
                          "with-sub-text": _ctx.withSubText,
                          "min-value": _ctx.hideHeader ? _ctx.checkMinValue(row.id) : _ctx.minValueGenericTable,
                          "max-value": _ctx.hideHeader ? _ctx.checkMaxValue(row.id) : _ctx.maxValueGenericTable,
                          value: cell.value
                        }, null, 8, ["with-sub-text", "min-value", "max-value", "value"])
                      ]),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.addDutchTranslatedDifferentSubtextRight(row.id)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}