import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ada216f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pedigree__card__row__header" }
const _hoisted_2 = {
  key: 0,
  class: "pedigree__type"
}
const _hoisted_3 = { class: "pedigree__name" }
const _hoisted_4 = { class: "pedigree__card__row__sub" }
const _hoisted_5 = {
  key: 0,
  class: "pedigree__card__row"
}
const _hoisted_6 = { class: "pedigree__card__row__static" }
const _hoisted_7 = { class: "pedigree__card__row" }
const _hoisted_8 = { class: "pedigree__card__row__static" }
const _hoisted_9 = { class: "pedigree__card__row" }
const _hoisted_10 = { class: "pedigree__card__row__static" }
const _hoisted_11 = {
  key: 1,
  class: "pedigree__card__row"
}
const _hoisted_12 = { class: "pedigree__card__row__static" }
const _hoisted_13 = {
  key: 2,
  class: "pedigree__card__row"
}
const _hoisted_14 = { class: "pedigree__card__row__static" }
const _hoisted_15 = {
  key: 3,
  class: "pedigree__card__row"
}
const _hoisted_16 = { class: "pedigree__card__row__static" }
const _hoisted_17 = {
  key: 4,
  class: "pedigree__card__row"
}
const _hoisted_18 = { class: "pedigree__card__row__static" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_chevronRightRegular = _resolveComponent("chevronRightRegular")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    elevation: "6",
    class: "pa-2"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: _normalizeClass([_ctx.getClickableClass(), "pedigree__card__row"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.windowWidth < _ctx.Device.MOBILEMAX)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getTypeText()), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.formatCardHeaderName(_ctx.pedigreeBull?.fullName, _ctx.pedigreeBull?.shortName)) + " ", 1),
              (!_ctx.isSire)
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 0,
                    color: "#b74d6d",
                    type: "mdi",
                    path: _ctx.mdiGenderFemale,
                    class: "pedigree__name__gender"
                  }, null, 8, ["path"]))
                : (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 1,
                    color: "#2f679c",
                    type: "mdi",
                    path: _ctx.mdiGenderMale,
                    class: "pedigree__name__gender"
                  }, null, 8, ["path"]))
            ])
          ]),
          (_ctx.isSire)
            ? (_openBlock(), _createBlock(_component_chevronRightRegular, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatCardSubHeaderName(_ctx.pedigreeBull?.fullName,
                _ctx.pedigreeBull?.shortName)), 1)
      ], 2),
      (_ctx.type === 'child')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('details.summary.first.artificialInseminationNumber')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.artificialInseminationNumber)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('details.summary.first.interbullNumber')), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.interbullNumber)), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('details.summary.first.dateOfBirthSmall')), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.formatDate(_ctx.pedigreeBull?.dateOfBirth)), 1)
      ]),
      (_ctx.type === 'child' || _ctx.type === 'parents')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('details.summary.first.raceInfo')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.breed)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type === 'child' || _ctx.type === 'parents')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('details.summary.first.inheritedFactors')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatValueOrDash(_ctx.formatInheritedFactors(_ctx.pedigreeBull?.inheritedFactors))), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type === 'child')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('details.summary.first.inbreedingCoefficient')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatInbreedingCoefficient(_ctx.pedigreeBull?.inbreedingCoefficient)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type === 'child')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('details.summary.first.kinshipRate')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.formatEmptyToDash(_ctx.pedigreeBull?.kinshipRate)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}