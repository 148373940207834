
import { defineComponent } from "vue";
import { formatNumber } from "@/scripts/format-number";
import { DocumentationHeaders } from "../models/table-headers";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiOpenInNew } from '@mdi/js';
import { formatLocale } from "../scripts/format-locale";

export default defineComponent({
    name: "DocumentationFormulasView",
    methods: {
        formatNumber(value: string) {
            return formatNumber(value)
        },
        openChapterLink() {
            window.open(this.$t('formulas.link'))
        }
    },
    components: {
        SvgIcon
    },
    data() {
        return {
            mdiOpenInNew: mdiOpenInNew,
            headerRows: [
                {
                    "id": "relative",
                    "cells": DocumentationHeaders
                }
            ],
            bodyRows: [
                {
                    "id": 1,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "<88"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 2,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "88-91"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 3,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "92-95"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 4,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "96-104"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(68.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 5,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "105-108"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(13.6, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 6,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": "109-112"
                        },
                        {
                            "id": "freq",

                            "value": this.$n(2.2, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                },
                {
                    "id": 7,
                    "cells": [
                        {
                            "id": "breeding",
                            "value": ">112"
                        },
                        {
                            "id": "freq",
                            "value": this.$n(0.1, 'decimal', formatLocale(this.$i18n.locale)) + '%'
                        }
                    ]
                }
            ],
        };
    },
});
