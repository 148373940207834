
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GeneralTableView',
    props: {
        bodyRows: {
            type: Array,
        },
        headerRows: {
            type: Array,
        }
    }
});
