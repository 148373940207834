
import { defineComponent } from 'vue';
import chevronRightRegular from '../assets/icons/chevronRightRegular.vue'
import { formatValueOrDash } from '../scripts/format-value-or-dash';
import { formatDateMixin } from '../mixins/format-date';
import { Device } from '../models/enums';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiGenderMale, mdiGenderFemale } from '@mdi/js';
import { formatLocale } from "../scripts/format-locale";
import {formatInheritedFactors} from "../scripts/format-inherited-factors";
import {formatInbreedingCoefficient} from "../scripts/format-inbreeding-coefficient";

export default defineComponent({
    name: 'PedigreeCardView',
    mixins: [formatDateMixin],
    props: {
        isSire: {
            type: Boolean,
        },
        type: {
            type: String,
        },
        pedigreeBull: {
            type: Object
        },
        typeParents: {
            type: String
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    emits: ["clicked"],
    data() {
        return {
            windowWidth: 0,
            mdiGenderFemale: mdiGenderFemale,
            mdiGenderMale: mdiGenderMale
        };
    },
    components: {
        chevronRightRegular,
        SvgIcon
    },
    methods: {
      formatValueOrDash,
      formatInbreedingCoefficient,
      formatInheritedFactors,
        FormatLocale(value: string) {
            return formatLocale(value)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        onClick() {
            this.$emit('clicked', this.pedigreeBull?.interbullNumber)
        },
        formatEmptyToDash(value: number | string) {
            return formatValueOrDash(value);
        },
        formatCardHeaderName(fullName: string, shortName: string){
            if (fullName) { 
                return fullName
            }

            else if (shortName) { 
                return shortName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        formatCardSubHeaderName(fullName: string, shortName: string){
            if (shortName) { 
                return shortName
            }
            
            else if (fullName) { 
                return fullName
            }

            else {
                return this.$t('details.unknown')
            }
        },
        getClickableClass() {
            return this.isSire ? "clickable" : "";
        },
        getTypeText(): string {
            switch (this.typeParents) {
                case 'child':
                    return ''
                case 'father':
                    return this.$t('pedigree.father')
                case 'mother':
                    return this.$t('pedigree.mother')
                case 'fatherfather':
                    return this.$t('pedigree.fatherfather')
                case 'fathermother':
                    return this.$t('pedigree.fathermother')
                case 'motherfather':
                    return this.$t('pedigree.motherfather')
                case 'mothermother':
                    return this.$t('pedigree.mothermother')
                case 'vvv':
                    return this.$t('pedigree.vvv')
                case 'mvv':
                    return this.$t('pedigree.mvv')
                case 'vmv':
                    return this.$t('pedigree.vmv')
                case 'mmv':
                    return this.$t('pedigree.mmv')
                case 'vvm':
                    return this.$t('pedigree.vvm')
                case 'mvm':
                    return this.$t('pedigree.mvm')
                case 'vmm':
                    return this.$t('pedigree.vmm')
                case 'mmm':
                    return this.$t('pedigree.mmm')
                default:
                    return ''
            }
        }
    },
    setup() {
        return {
            Device
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
