import { ComputedRef, computed } from "vue";
import VueI18n from "../i18n/entry";
import { HeaderValues } from "./enums";

export const GeneralColumns: ComputedRef<any[]> = computed(() => {
  return [
    {
      id: HeaderValues.GENERAL,
      value: "",
    },
    {
      id: HeaderValues.BAR,
      value: "9296100104108",
      slotName: "custom-styled-column1",
    },
    {
      id: HeaderValues.FW,
      value: VueI18n.global.t("details.header-row-breeding"),
    },
  ];
});

export const GeneralProdColumns: ComputedRef<any[]> = computed(() => {
  return [
    {
      id: HeaderValues.GENERAL,
      value: "",
    },
    {
      id: HeaderValues.FW,
      value: VueI18n.global.t("details.header-row-breeding"),
    },
    {
      id: HeaderValues.BTBH,
      value: VueI18n.global.t("details.header-row-percentage"),
    },
  ];
});

export const CustomColumns: ComputedRef<any[]> = computed(() => {
  return [
    {
      id: HeaderValues.PROD,
      value: "",
    },
    {
      id: HeaderValues.FW,
      value: VueI18n.global.t("details.header-row-breeding"),
    },
    {
      id: HeaderValues.BTBH,
      value: VueI18n.global.t("details.header-row-percentage"),
    },
  ];
});

export const CustomColumnsBreeding: ComputedRef<any[]> = computed(() => {
  return [
    {
      id: HeaderValues.EMPTY,
      value: "",
    },
    {
      id: HeaderValues.BAR,
      value: "9296100104108",
      slotName: "custom-styled-column1",
    },
    {
      id: HeaderValues.FW,
      value: VueI18n.global.t("details.header-row-breeding"),
      horizontalAlignment: "center",
      className: "semi-bold",
    },
    {
      id: HeaderValues.BTBH,
      value: VueI18n.global.t("details.header-row-percentage"),
      horizontalAlignment: "center",
      className: "semi-bold",
    },
    {
      id: HeaderValues.DAUGHTERS,
      value: VueI18n.global.t("details.breeding-values.table-header-daughter"),
      horizontalAlignment: "center",
      className: "semi-bold",
    },
    {
      id: HeaderValues.COMPANIES,
      value: VueI18n.global.t("details.breeding-values.table-header-companies"),
      horizontalAlignment: "center",
      className: "semi-bold",
    },
    {
      id: HeaderValues.SOURCE,
      value: VueI18n.global.t("details.breeding-values.table-header-source"),
      horizontalAlignment: "center",
      className: "semi-bold",
    },
  ];
});

export const ProdColumnsBreeding: ComputedRef<any[]> = computed(() => {
    return [
        {
            id: 'lactatie',
            value: VueI18n.global.t('details.production.Header.Lactation'),
            className: 'semi-bold',
            slotName: 'custom-styled-column4',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.kgMilk'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.perFat'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.perProtein'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.perLactose'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.kgFat'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.kgProtein'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.kgLactose'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.Inet'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.Ureum'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.Somatic'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.btbh'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.Daughter'),
            slotName: 'custom-styled-column1',
        },
        {
            id: "",
            value: VueI18n.global.t('details.production.Header.Companies'),
            slotName: 'custom-styled-column1',
        },
    ];
  });

  export const DocumentationHeaders: ComputedRef<any[]> = computed(() => {
    return [
      {
        id: HeaderValues.FW,
        value: VueI18n.global.t('formulas.relative.header-breeding'),
      },
      {
        id: HeaderValues.BTBH,
        value: VueI18n.global.t('formulas.relative.header-frequency'),
      },
    ];
  });