
import { defineComponent } from "vue";
import { formatValueOrDash } from "../scripts/format-value-or-dash";
import { formatGenomicValue } from "../scripts/format-genomic-value";
import { mapGetters, useStore } from "vuex";
import { computed, ComputedRef } from "vue";
import { BreedingValue, BreedingValueSubValue } from "../models/bullinfo";
import GeneralTableView from "./BlockView/GeneralTableView.vue";
import CustomTableView from "./BlockView/CustomTableView.vue";
import { TableName, HeaderValues } from "../models/enums";
import { BreedingValueGroup } from "../models/bullinfo";
import { GeneralProdColumns, CustomColumns } from "../models/table-headers";
import { formatNumber } from '@/scripts/format-number';

export default defineComponent({
  name: "GenomicsView",

  data() {
    return {
      generalColumns: [
        {
          id: "general-column",
          cells: GeneralProdColumns
        },
      ],
      customColumns: [
        {
          id: "production-column",
          cells: CustomColumns
        },
      ],
    };
  },
  setup() {
    const store = useStore();

    const generalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.GENERAL);
      });
    const healthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.HEALTH);
      });
    const functionalBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.FUNCTIONAL);
      });
    const birthBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.BIRTH);
      });
    const exteriorAboveBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORABOVE);
      });

    const exteriorBelowBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EXTERIORBELOW);
      });

    const efficiencyBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.EFFICIENCY);
      });
    const productionBreedingValueGroupList: ComputedRef<BreedingValueGroup> =
      computed(() => {
        return store.state.bullDetail.breedingValueBase?.breedingValueGroupList?.filter((item) => item.tagName == TableName.PRODUCTION);
      });


    return {
      generalBreedingValueGroupList,
      healthBreedingValueGroupList,
      functionalBreedingValueGroupList,
      birthBreedingValueGroupList,
      exteriorAboveBreedingValueGroupList,
      exteriorBelowBreedingValueGroupList,
      efficiencyBreedingValueGroupList,
      productionBreedingValueGroupList,
    };
  },
  components: {
    GeneralTableView,
    CustomTableView
  },
  computed: {
    ...mapGetters({
      bullDetail: "getBullDetail",
    }),
  },
  methods: {
    mapCustomProductionBreedingValues(list: BreedingValue[], tagName: string) {
      return list
        .filter((value: BreedingValue) => value.showOnDetailView)
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`)
            },
            {
              id: 'value',
              value: value.value == 0 ? 0 : formatValueOrDash(value.value)
            },
            {
              id: '%btbh',
              value: value.reliabilityPercentage == 0 ? 0 : formatValueOrDash(value.reliabilityPercentage)
            },
          ],
        }));
    },
    formatEmptyToDash(value: number | string | undefined): number | string | undefined {
      return formatValueOrDash(value);
    },
    formatGenomicValue(value: number | undefined): number | string {
      return formatGenomicValue(value);
    },
    mapCustomBreedingValues(list: BreedingValue[], tagName: string) {
      let mappedBreedingValues;
      // eslint-disable-next-line
        mappedBreedingValues = list.map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
            },
            {
              id: HeaderValues.VALUE,
              value: value.value === undefined || value.value === null ? '-' : formatNumber(String(value.value))
            },
            {
              id: HeaderValues.BTBH,
              value: value.reliabilityPercentage == 0 ? 0 : formatValueOrDash(value.reliabilityPercentage)
            },
          ],
        }))

      return mappedBreedingValues;
    },

    mapGeneralBreedingValues(list: BreedingValue[], tagName: string) {
      const breedingValueArray: BreedingValue[] = [];
      for (let index = 0; index < list.length; index++) {
        const element: BreedingValue = list[index];
        breedingValueArray.push(element);

        if (element.tagName === "BirthIndex" && tagName === "Birth") {
          const subValueList = element.breedingValueSubValueList;
          if (subValueList === undefined){
            //
          }
          else{
            const birthEase = subValueList.filter(
              (subvalue: BreedingValueSubValue) =>
                subvalue.tagName === "BirthEase"
            )[0];
            birthEase.showOnDetailView = true;
            breedingValueArray.push(birthEase as BreedingValue);
          }
        }
      }
      const alist = breedingValueArray
        .map((value: BreedingValue) => ({
          id: value.tagName,
          cells: [
            {
              id: value.tagName,
              value: this.$t(`details.${tagName.toLowerCase()}.${value.tagName}`),
            },
            {
              id: HeaderValues.VALUE,
              value: value.value == 0 ? 0 : formatValueOrDash(value.value)
            },
            {
              id: HeaderValues.BTBH,
              value: value.reliabilityPercentage == 0 ? 0 : formatValueOrDash(value.reliabilityPercentage)
            },
          ],
        }));

        return alist
    },
  },
});
