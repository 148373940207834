import { createI18n } from "vue-i18n";

import { messages, defaultLocale } from "@/i18n/config";

export const i18n = createI18n({
  numberFormats: {
    // More info: https://tinyurl.com/s2sjz9cw
    'en-US': {
      currency: {
        style: 'currency', currency: 'USD', notation: 'standard'
      },
      decimal: {
        style: 'decimal', maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },
    'nl-NL': {
      currency: {
        style: 'currency', currency: 'USD', notation: 'standard'
      },
      decimal: {
        style: 'decimal', maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    },
  },
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  globalInjection: true,
});

export default i18n;
