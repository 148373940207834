import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d42fcd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "custom-cell-additionalValue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, { density: "compact" }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerRows[0].cells, (item) => {
            return (_openBlock(), _createElementBlock("th", {
              key: item.id,
              class: "text-left"
            }, _toDisplayString(item.value), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bodyRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.cells, (cell) => {
              return (_openBlock(), _createElementBlock("td", {
                class: "text-left",
                key: cell.id
              }, [
                (cell.id === 'additionalValue')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formatNumber(cell.value)), 1))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass({ 'measurement-value': cell.id === 'measurement' })
                    }, _toDisplayString(_ctx.formatNumber(cell.value)), 3))
              ]))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}