
import { defineComponent } from "vue";

export default defineComponent({
    name: "chevronRightRegular",
    props: {
        width: {
            type: Number,
            default: 10
        },
        height: {
            type: Number,
            default: 16
        }
    },
    computed: {
        viewBox(): string {
            return '0 0 ' + this.width + ' ' + this.height
        }
    }

});
