const INTERBULL_NUMBER = 'interbullNumber';
const FULL_NAME = 'fullName';
const SHORT_NAME = 'shortName';
const LIFE_NUMBER = 'lifeNumber';
const DATE_OF_BIRTH = 'dateOfBirth';
const ARTIFICIAL_INSEMINATION_NUMBER = 'artificialInseminationNumber';
const DUTCH_FLEMISH_INDEX = 'dutchFlemishIndex';
const PARENT_BULLS = 'parentBull';
const HAIR_COLOR = 'hairColor';
const TRIPLEA_CODE = 'tripleACode';
const INHERITED_FACTORS = 'inheritedFactors';
const INBREEDING_COEFFICIENT = 'inbreedingCoefficient';
const KINSHIP_RATE = 'kinshipRate';
const SOURCE_BREEDING_VALUES = 'sourceBreedingValues';
const BREEDING_VALUE_BASES = 'breedingValueBases';
const HAPLO_TYPES = 'haploTypes';
const BETA_CASEIN = 'betaCasein';
const KAPPA_CASEIN = 'kappaCasein';
const PARENT_SIRE = 'parentSire';
const PARENT_DAM = 'parentDam';
const RACE_INFO = 'raceInfo';
const INET = "inet";
const MILK_KILOGRAMS = "milkKilograms";
const PERCENTAGE_FAT = "percentageFat";
const PERCENTAGE_PROTEIN = "percentageProtein";
const FOOD_COST_SAVING = "foodCostSaving";
const LIFE_SPAN = "lifeSpan";
const UDDER_HEALTH = "udderHealth";
const FERTILITY = "fertility";
const CLAW_HEALTH = "clawHealth";
const BIRTH_INDEX = "birthIndex";
const BIRTH_EASE = "birthEase";
const TOTAL_EXTERIOR = "totalExterior";
const UDDER = "udder";
const LEGWORK = "legwork";
const PRIMARYKEY = "primaryKey";
const SOURCEOFEVALUATION = "sourceOfEvaluation";
const CALCULATIONTYPE = "calculationType";
const REFERENCE_BASE = "referenceBase";
const DATE_OF_OFFICIAL_EVALUATION = "dateOfOfficialEvaluation";
'inheritedFactors';
'tripleACode';

export default class SearchFields {
  static searchView = [
    INTERBULL_NUMBER,
    FULL_NAME,
    SHORT_NAME,
    LIFE_NUMBER,
    DATE_OF_BIRTH,
    ARTIFICIAL_INSEMINATION_NUMBER,
    DUTCH_FLEMISH_INDEX,
    INET,
    MILK_KILOGRAMS,
    PERCENTAGE_FAT,
    PERCENTAGE_PROTEIN,
    FOOD_COST_SAVING,
    LIFE_SPAN,
    UDDER_HEALTH,
    FERTILITY,
    CLAW_HEALTH,
    BIRTH_INDEX,
    BIRTH_EASE,
    TOTAL_EXTERIOR,
    UDDER,
    LEGWORK,
    PRIMARYKEY,
    SOURCEOFEVALUATION,
    INHERITED_FACTORS,
    CALCULATIONTYPE,
    REFERENCE_BASE
  ];

  static detailView = [
    INTERBULL_NUMBER,
    FULL_NAME,
    SHORT_NAME,
    LIFE_NUMBER,
    DATE_OF_BIRTH,
    ARTIFICIAL_INSEMINATION_NUMBER,
    DUTCH_FLEMISH_INDEX,
    PARENT_BULLS,
    LIFE_NUMBER,
    HAIR_COLOR,
    TRIPLEA_CODE,
    INHERITED_FACTORS,
    INBREEDING_COEFFICIENT,
    KINSHIP_RATE,
    SOURCE_BREEDING_VALUES,
    BREEDING_VALUE_BASES,
    HAPLO_TYPES,
    BETA_CASEIN,
    KAPPA_CASEIN,
    PRIMARYKEY,
    SOURCEOFEVALUATION,
    CALCULATIONTYPE,
    REFERENCE_BASE,
    DATE_OF_OFFICIAL_EVALUATION
  ];

  static pedigreeView = [
    FULL_NAME,
    SHORT_NAME,
    DATE_OF_BIRTH,
    ARTIFICIAL_INSEMINATION_NUMBER,
    INTERBULL_NUMBER,
    KINSHIP_RATE,
    INBREEDING_COEFFICIENT,
    RACE_INFO,
    LIFE_NUMBER,
    INHERITED_FACTORS,
    PARENT_SIRE,
    PARENT_DAM,
    PRIMARYKEY,
    SOURCEOFEVALUATION,
    CALCULATIONTYPE
  ];

  static breedingValuesView = [INTERBULL_NUMBER, BREEDING_VALUE_BASES];
}
