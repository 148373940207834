
import { defineComponent } from "vue";
import { LayoutLanguages } from "../i18n/config/locales";
import { defaultLocale } from "../i18n/config";
import crvLogo from '../assets/logo/crvLogo.vue';
import chevronLeftSolid from '../assets/icons/chevronLeftSolid.vue';
import { mapGetters, mapMutations } from "vuex";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose, mdiMenu } from '@mdi/js';
import {BullService} from "@/services/BullService";

export default defineComponent({
    name: "SireHeader",
    components: { crvLogo, chevronLeftSolid, SvgIcon },
    data: () => ({
        mdiClose: mdiClose,
        mdiMenu: mdiMenu,
        LayoutLanguages, defaultLocale,
    }),
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setLayout: "setLayout",
            setPreviousLayout: "setPreviousLayout",
        }),
        goBackToSearch() {
            this.setBullDetail({})
            this.setLayout('search-view')
        },
        goToTranslationView() {
            this.setPreviousLayout(this.getLayout)
            this.setLayout('translation-view')
        },
        goToOtherView() {
            this.setLayout(this.getPreviousLayout)
        },
        async showMaintenanceView(){
          if (await new BullService().isUnderMaintenance()) {
            this.setLayout('maintenance-view')
          }
        }
    },
    computed: {
        ...mapGetters({
            getLayout: "getLayout",
            getSearchText: "getSearchText",
            getPreviousLayout: "getPreviousLayout",
        }),
    },
    mounted() {
        this.showMaintenanceView()
        //TODO: routing and such should not be inside this component.
    }
});
