<template>
    <div :class="exteriorClass" class="bv-bar">
        <div class="bar half">
            <span :class="valueColorClass" :style="fillStyle"></span>
            <div v-if="value > maxValue && value != null" class="triangle-right"></div>
            <div v-if="value < minValue && value != null && value != 0" class="triangle-left"></div>
        </div>
        <div class="middle"></div>
    </div>
</template>

<script>
export default {
    name: 'HorizontalBreedingValueBar',
    props: {
        value: {
            type: [ String, Number ],
            required: false,
        },
        minValue: {
            type: [ String, Number ],
            required: false,
            default: 92
        },
        maxValue: {
            type: [ String, Number ],
            required: false,
            default: 108
        },
        withSubText: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            middleValue: (this.minValue + this.maxValue) / 2
        }
    },
    computed: {
        valueColorClass() {
            return `${this.value > this.middleValue ? 'plus' : 'min'}`
        },
        exteriorClass() {
            return `${this.withSubText ? 'exteriorTable' : ''}`
        },
        fillStyle() {
            if (isNaN(this.value) || this.value === undefined || this.value == null || this.value == 0){ return 0; }

            if (this.value > this.maxValue || this.value < this.minValue) {
                return {
                    width: `${this.withSubText ? '27%' : '45%'}`
                }
            } else {
                const precision = 1000000; // We need to multiply the values by this so we can work with smaller numbers
                const percentage = ((this.maxValue - this.minValue) / 100) * precision;
                const maxwidth = this.withSubText ? 27 : 45;
                const widthpercentage = (maxwidth / (percentage * (this.maxValue - this.minValue))) * precision;
                let valuePercentage = 0;

                if (this.value < this.middleValue) {
                    valuePercentage = (((this.value - this.middleValue) * -1) * 2 * percentage) * widthpercentage
                } else {
                    valuePercentage = ((this.value - this.middleValue) * 2 * percentage) * widthpercentage
                }
                valuePercentage = valuePercentage / precision
                return {
                    width: `${valuePercentage}%`,
                }
            }

        }
    },
}
</script>


<style lang="scss">
@import '../scss/horizontal-breeding-value-bar.scss';
</style>
