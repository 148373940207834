<script setup lang="ts">

</script>

<template>
  <div class="footer">
    <p style="font-size: 12px; text-align: center; vertical-align: middle; display: table-cell">
      {{ $t("footer.disclaimer")}}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '../scss/footer.scss';
</style>