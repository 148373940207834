
import { defineComponent } from "vue";
import chevronLeftSolid from '../assets/icons/chevronLeftSolid.vue';
import GeneralView from './GeneralView.vue';
import GenomicsView from './GenomicsView.vue';
import DocumentationFormulasView from './DocumentationFormulasView.vue';
import { Device, Colors } from '../models/enums';
import PedigreeView from './PedigreeView.vue';
import BreedingValuesView from './BreedingValuesView.vue';
import { mapGetters, mapMutations, useStore } from "vuex";
import { BullService } from "../services/BullService";
import { BullInfo } from "@/models/bullinfo";
import { formatValueOrDash } from "@/scripts/format-value-or-dash";
import { createWithEmptyValues } from "@/scripts/init-bullinfo-base";
import SireFooter from '../components/SireFooter.vue'

const bullService = new BullService();

export default defineComponent({
    name: "BullDetailView",
    components: {
        chevronLeftSolid,
        GeneralView,
        PedigreeView,
        BreedingValuesView,
        GenomicsView,
        DocumentationFormulasView,
        SireFooter
    },
    data() {
        return {
            baseColor: Colors.BASEBLUE,
            tab: 'one',
            windowWidth: 0,
            componentKey: 0,
            loading: false
        }
    },
    created() {
        this.resetScrollToTop()
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    setup() {
        const store = useStore();


        return {
            Device,
            store
        }
    },
    computed: {
        ...mapGetters({
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
        getHairColorValue: {
            get(): { optionText: string, id: string } {
                return { optionText: this.hairColorText(this.getBaseColor), id: this.getBaseColor }
            },
            async set(value) {
                this.loading = true
                const calcType = this.getCalcType;
                const baseColor = value.id;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
                this.loading = false
                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
                this.loading = false

                }

            }
        },
        getHairColorOptions(): any {
            return [
                {
                    optionText: this.$t('details.haircolor.black-fur'),
                    id: 'Zwartbont'
                },
                {
                    optionText: this.$t('details.haircolor.red-fur'),
                    id: 'Roodbont'
                },
                {
                    optionText: this.$t('details.haircolor.double-purpose'),
                    id: 'Dubbeldoel'
                },
                {
                    optionText: this.$t('details.haircolor.belgian-blue'),
                    id: 'Belgischblauw'
                }
            ]
        }
    },
    methods: {
        ...mapMutations({
            setBullDetail: "setBullDetail",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
            setBreedingValueBase: "setBreedingValueBase"
        }),
        resetScrollToTop(): void {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async setGenonimcView() {
            this.loading = true;

                const calcType = 'MARKER_EFFECT';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    //TODO: calling following bullService currently raises an 404 for marker-effects, are these not loaded or broken in dev?
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        async setGeneralView() {
            this.loading = true;

            const calcType = 'GENOMIC_BREEDING_VALUE';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        async setBreedingValue() {
            this.loading = true;
            const calcType = 'GENOMIC_BREEDING_VALUE';
                const baseColor = this.getBaseColor;
                const interbullNumber = this.bullDetail.interbullNumber
                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                try {
                    const result = await bullService.getForDetailView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                    this.setBullDetail(result);
            this.loading = false;

                }
                catch (error) {
                    this.setBreedingValueBase(createWithEmptyValues());
            this.loading = false;

                }
        },
        formatEmptyToDash(value: number | string | undefined): number | string | undefined {
            return formatValueOrDash(value);
        },
        goBackToSearch() {
            this.setBullDetail({})
            this.setLayout('search-view')
        },
        hairColorText(val: string) {
            switch (val) {
                case 'Belgischblauw':
                    return this.$t('details.haircolor.belgian-blue')
                case 'Dubbeldoel':
                    return this.$t('details.haircolor.double-purpose')
                case 'Roodbont':
                    return this.$t('details.haircolor.red-fur')
                case 'Zwartbont':
                    return this.$t('details.haircolor.black-fur')
                default:
                    return this.$t('details.haircolor.black-fur')
            }
        },
        async searchBullsByITB(value) {
            try {
                const result: BullInfo = await bullService.getForDetailView(value);
                this.setBullDetail(result);
                if (result) {
                    this.setLayout('bull-detail-view')
                    this.resetScrollToTop()
                }
            } catch (error) {
                return
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        createPrimaryKey(interbullNumber: string, color: string, type: string): string {
            const key = `${interbullNumber}-${color}-${type}`
            return key
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
