import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71fa0569"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__container" }
const _hoisted_3 = {
  key: 0,
  class: "tab"
}
const _hoisted_4 = { class: "tab__container" }
const _hoisted_5 = { class: "tab__logo" }
const _hoisted_6 = { class: "tab__logo__content" }
const _hoisted_7 = { class: "tab__logo__chevron" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_chevronLeftSolid = _resolveComponent("chevronLeftSolid")!
  const _component_crvLogo = _resolveComponent("crvLogo")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_select, {
          "menu-props": {theme: 'light', id: 'headeraaa'},
          class: "header__select",
          modelValue: _ctx.$i18n.locale,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
          "item-value": "param",
          "item-title": (item)=>`${item.item} (${item.title})`,
          items: _ctx.LayoutLanguages,
          variant: "underlined"
        }, {
          selection: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.raw.title), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "item-title", "items"])
      ])
    ]),
    (_ctx.getLayout !== 'maintenance-view')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.getLayout === 'bull-detail-view')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBackToSearch && _ctx.goBackToSearch(...args)))
                      }, [
                        _createVNode(_component_chevronLeftSolid)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goBackToSearch()))
                }, [
                  _createVNode(_component_crvLogo)
                ]),
                (_ctx.getLayout === 'translation-view')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "tab__logo__hamburger",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToOtherView && _ctx.goToOtherView(...args)))
                    }, [
                      _createVNode(_component_svg_icon, {
                        type: "mdi",
                        path: _ctx.mdiClose
                      }, null, 8, ["path"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "tab__logo__hamburger",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToTranslationView && _ctx.goToTranslationView(...args)))
                    }, [
                      _createVNode(_component_svg_icon, {
                        type: "mdi",
                        path: _ctx.mdiMenu
                      }, null, 8, ["path"])
                    ]))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}