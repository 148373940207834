
import { LayoutLanguages } from "./i18n/config/locales";
import { defaultLocale } from "./i18n/config";
import SireHeader from './components/SireHeader.vue'
// import SireFooter from './components/SireFooter.vue'
// TODO: SireFooter currently is only present within maintance-view, rebuild header and show footer, every page?
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: { SireHeader },
  data: () => ({ LayoutLanguages, defaultLocale }),
});

