
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import PedigreeCardView from '../components/PedigreeCardView.vue';
import { BullService } from "../services/BullService";
import { BullInfo } from "@/models/bullinfo";
import { Device } from '../models/enums';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

const bullService = new BullService();

export default defineComponent({
    name: 'PedigreeView',
    components: {
        PedigreeCardView,
        SvgIcon
    },
    data() {
        return {
            windowWidth: 0,
            bullInfo: {} as BullInfo,
            mdiChevronLeft: mdiChevronLeft,
            mdiChevronRight: mdiChevronRight
        };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    computed: {
        ...mapGetters({
            getPedigreeState: 'getPedigreeState',
            getPedigreeBull: "getPedigreeBull",
            bullDetail: "getBullDetail",
            getCalcType: "getCalcType",
            getBaseColor: "getBaseColor",
        }),
    },
    methods: {
        ...mapMutations({
            setPedigreeState: "setPedigreeState",
            setPedigreeBull: "setPedigreeBull",
            setLayout: "setLayout",
            setCalcType: "setCalcType",
            setBaseColor: "setBaseColor",
        }),
        async onClickSire(value: string) {
            const calcType = this.getCalcType;
                const baseColor = this.getBaseColor;
                const interbullNumber = value;
            try {

                this.setCalcType(calcType);
                this.setBaseColor(baseColor);
                const pedigree: BullInfo = await bullService.getForPedigreeView(this.createPrimaryKey(interbullNumber, baseColor, calcType));
                this.setPedigreeBull(pedigree)
                this.setPedigreeState(1)
            }
            catch (error) {
                return
            }
            if (value) {
                this.$emit('clicked', this.createPrimaryKey(interbullNumber, baseColor, calcType))
            }
        },
        onClickNextPedigree() {
            this.setPedigreeState(this.getPedigreeState + 1)
        },
        onClickPreviousPedigree() {
            this.setPedigreeState(this.getPedigreeState - 1)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getLeftSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.first-generation')
                case 1:
                    return this.$t('pedigree.first-generation')
                case 2:
                    return this.$t('pedigree.second-generation')
                case 3:
                    return this.$t('pedigree.third-generation')
                default:
                    return this.$t('pedigree.first-generation')
            }
        },
        getRightSwitchText(): string {
            switch (this.getPedigreeState) {
                case 0:
                    return this.$t('pedigree.second-generation')
                case 1:
                    return this.$t('pedigree.third-generation')
                case 2:
                    return this.$t('pedigree.fourth-generation')
                case 3:
                    return this.$t('pedigree.fourth-generation')
                default:
                    return this.$t('pedigree.third-generation')
            }
        },
        createPrimaryKey(interbullNumber: string, color: string, type: string): string {
            const key = `${interbullNumber}-${color}-${type}`
            return key
        }
    },
    setup() {
        return {
            Device,
        }
    },
    async mounted() {
        try {
            const pedigree: BullInfo = await bullService.getForPedigreeView(this.bullDetail.primaryKey);
            this.setPedigreeBull(pedigree)
        }
        catch (error) {
            return
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    }
});
